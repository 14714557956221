.hero-section {
  @apply flex flex-col md:flex-row items-center justify-between gap-8 p-6 text-primary;
}

.hero-content {
  @apply md:w-1/2 flex flex-col justify-center;
}

.hero-title {
  font-family: 'Ubuntu', sans-serif;
}

.hero-button {
  @apply hover:bg-blue-800 font-bold py-2 px-4 rounded-md mt-4;
}

.hero-carousel {
  @apply md:w-1/2 w-full h-full flex justify-center items-center;
}

.carousel-image {
  @apply rounded-lg shadow-lg w-full h-auto object-cover;
}

@screen md {
  .hero-carousel {
    @apply h-72; /* or set an appropriate height for the carousel */
  }
}

@screen sm {
  .hero-section {
    @apply flex-col;
  }

  .hero-carousel {
    @apply w-full mt-6;
  }
}

.nav-inactive {
  opacity: 0.8;
  font-size: 12px;
}

.text-navbar-title {
  font-size: 16px;
  line-height: 30px;
}

.leadership-team-img {
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-radius: 8px;
}
.tech-img-wrapper-1,
.tech-img-wrapper-3,
.tech-img-wrapper-4,
.tech-img-wrapper-5,
.tech-img-wrapper-6 {
  width: 70%;
}
.tech-img-wrapper-7,
.tech-img-wrapper-8,
.tech-img-wrapper-9 {
  width: 70%;
}
.tech-img-common.tech-img-2,
.tech-img-common.tech-img-1,
.tech-img-common.tech-img-3,
.tech-img-common.tech-img-4,
.tech-img-common.tech-img-5,
.tech-img-common.tech-img-6,
.tech-img-common.tech-img-7,
.tech-img-common.tech-img-8,
.tech-img-common.tech-img-9 {
  width: 100%;
  height: 400px;
}

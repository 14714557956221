/* styles/global.css */
.gradient-background {
  @apply font-sans; /* Ensure the font is applied */
  min-height: 100vh; /* Ensure it covers at least the viewport height */
  padding-bottom: 0px;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html,
body {
  height: 100%;
  margin: 0;
}
